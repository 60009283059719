
import { Component, Prop, Vue } from "vue-property-decorator";
import t from "@common/src/i18n/t";

@Component({
  inheritAttrs: false
})
export default class TipsDialog extends Vue {
  @Prop({ default: "480px" })
  width: string;
  @Prop({ default: "" })
  content: string;
  @Prop({ default: true })
  showCancel: string;
  @Prop({ default: true })
  showConfirm: string;
  @Prop({ default: t("test.take-away") })
  cancelText: string;
  @Prop({ default: t("global.sure") })
  confirmText: string;
  @Prop({ type: Boolean, default: false })
  hideFooter;
  @Prop({ type: Boolean, default: false })
  confirmBtnLoading;
  @Prop()
  cancel: any;
  created() {
    // console.log(1111, this.$listeners);
  }
  handleClose() {
    if (this.$listeners.cancel) {
      this.$emit("cancel");
    } else {
      this.$emit("update:visible", false);
    }
  }
  confirm() {
    this.$emit("confirm");
  }
}
